<template>
  <div>
    <v-toolbar flat>
      <v-btn class="ma-2" color="orange darken-2" @click="$store.dispatch('backRouter')" dark>
        <v-icon dark left>mdi-arrow-left</v-icon>Back
      </v-btn>

      <v-toolbar-title>Player Detail</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-btn color="primary" dark @click="initialize" class="mb-2">Sync</v-btn>
    </v-toolbar>
    <json-viewer name="df" :value="jsonData" :expand-depth="5" theme="my-awesome-json-theme" sort></json-viewer>
  </div>
</template>
<script>
export default {
  data: () => ({
    jsonData: {}
  }),
  methods: {
    setState(state) {
      if (state) return "Aktif";
      return "Pasif";
    },

    async initialize() {
      if (this.$route.params.id) {
        await this.$store.dispatch("players/getPlayer", this.$route.params.id);
        this.jsonData = this.$store.state.players.player;
      }
    }
  },
  async mounted() {
    await this.initialize();
  }
};
</script>